<template>
  <v-container v-if="Project" class="pa-0">
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <v-row no-gutters class="mb-4">
          <h2>{{ $t('PageTitles.ProjectDetails') }}</h2>
        </v-row>

        <v-simple-table dense class="noHoverEffect pa-2">
          <tbody>
            <tr>
              <td class="font-weight-bold" style="width: 200px">{{ $t('ProjectListHeaders.Name') }}</td>
              <td>{{ Project.name }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('ProjectListHeaders.Leader') }}</td>
              <td>
                <div v-if="Project.leader">
                  {{ Project.leader.name }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('UserListHeaders.CompanyName') }}</td>
              <td v-if="AdminOrIsInSameCompany">
                <router-link :to="{ name: 'CompanyDetails', params: { Id: Project.companyId } }">{{ Project.companyName }}</router-link>
              </td>
              <td v-else>
                {{ Project.companyName }}
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">{{ $t('ProjectListHeaders.Expenses') }}</td>
              <td>{{ $util.formatCurrency(Project.expenses) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('ProjectListHeaders.Created') }}</td>
              <td>{{ $util.formatDate(Project.created, true) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('ProjectListHeaders.StartDate') }}</td>
              <td>{{ $util.formatDate(Project.start) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('ProjectListHeaders.Updated') }}</td>
              <td>{{ $util.formatDate(Project.updated, true) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('ProjectListHeaders.EndDate') }}</td>
              <td>{{ $util.formatDate(Project.estimatedEnd) }}</td>
            </tr>
            <tr v-if="Project.calculatedEnd != null && Risks">
              <td class="font-weight-bold">{{ $t('ProjectListHeaders.EstimatedEnd') }}</td>
              <td>
                {{ $util.formatDate(Project.calculatedEnd) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-card-actions class="px-0 mt-4">
          <v-spacer />
          <v-btn
            :block="$vuetify.breakpoint.mobile"
            @click="EditProject"
            v-if="HasFullAccessPermissions"
            depressed
            :color="$vuetify.theme.dark ? 'black' : 'white'"
          >
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('ButtonsLabel.Edit') }}
          </v-btn>
        </v-card-actions>
        <div class="mt-6" v-if="Risks">
          <v-row no-gutters align="center" class="mt-4">
            <v-col cols="2" sm="4">
              <h2>{{ $t('PageTitles.Risk') }}</h2>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                hide-details
                dense
                v-model="search"
                :label="$t('message.Search')"
                :class="$vuetify.breakpoint.mobile ? 'mt-4' : 'pa-4'"
                background-color="searchField"
              />
            </v-col>
            <v-col cols="12" md="4" align="right" v-if="HasFullAccessPermissions">
              <v-btn
                v-if="isManager"
                depressed
                :color="$vuetify.theme.dark ? 'black' : 'white'"
                :block="$vuetify.breakpoint.mobile"
                :class="$vuetify.breakpoint.mobile ? 'my-4' : ''"
                @click="addRisk"
              >
                <v-icon left>mdi-plus-box-outline</v-icon>
                {{ $t('ButtonsLabel.AddRisk') }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- </template> -->
          <v-data-table class="mt-4 py-4" :items="Risks" :search="search" :headers="getHeaders" :items-per-page="15" sort-desc :footer-props="footerProps">
            <template #[`item.title`]="{ item }">
              <router-link :to="{ name: 'RiskDetails', params: { riskId: item.id } }">{{ item.title }}</router-link>
            </template>
            <template #[`item.probability`]="{ item }">
              {{ $util.convertConsequenceToText(item.probability) }}
            </template>
            <template #[`item.timeLine`]="{ item }">
              {{ $util.convertConsequenceToText(item.timeLine) }}
            </template>
            <template #[`item.expenses`]="{ item }">
              {{ $util.convertConsequenceToText(item.expenses) }}
            </template>
            <template #[`item.scope`]="{ item }">
              {{ $util.convertConsequenceToText(item.scope) }}
            </template>
            <template #[`item.resource`]="{ item }">
              {{ $util.convertConsequenceToText(item.resource) }}
            </template>
            <template #[`item.owner`]="{ item }">
              <div v-if="item.owner">
                {{ item.owner.name }}
              </div>
            </template>
          </v-data-table>
          <!-- </r-card> -->
        </div>
      </v-col>
      <v-col cols="12" md="8" class="mt-4" v-if="isManager">
        <v-row no-gutters align="center">
          <v-col cols="12" sm="4">
            <h2>{{ $t('UserListHeaders.InternalPermissionsUsers') }}</h2>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              hide-details
              dense
              v-model="searchPermissions"
              :label="$t('message.Search')"
              :class="$vuetify.breakpoint.mobile ? 'my-4' : 'pa-4'"
              background-color="searchField"
            />
          </v-col>
        </v-row>
        <v-data-table :items="getCompanyUsers" :search="searchPermissions" :headers="getPermissionsHeaders" :items-per-page="15">
          <template #[`item.userPermissions`]="{ item }"> {{ $util.userPermissionsToText(item.userPermissions) }} </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="8" class="mt-4" v-if="getExternalUsers.length > 0 && isManager">
        <v-row no-gutters align="center">
          <v-col cols="2" sm="4">
            <h2>{{ $t('UserListHeaders.ExternalPermissionsUsers') }}</h2>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              hide-details
              dense
              v-model="searchExternalPermissions"
              :label="$t('message.Search')"
              class="pa-4"
              background-color="searchField"
            />
          </v-col>
        </v-row>
        <v-data-table :items="getExternalUsers" :search="searchExternalPermissions" :headers="getPermissionsHeaders" :items-per-page="15">
          <template #[`item.userPermissions`]="{ item }"> {{ $util.userPermissionsToText(item.userPermissions) }} </template>
        </v-data-table>
      </v-col>
    </v-row>
    <create-edit-project v-if="isManager" ref="CreateEditProjectRef" @project-updated="bubbleEmit" />
    <create-edit-risk ref="CreateEditRiskRef" @risk-updated="getProject" />
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditProject from '@/components/Dialogs/CreateEditProject.vue';
import CreateEditRisk from '@/components/Dialogs/CreateEditRisk.vue';
import { mapGetters } from 'vuex';
export default {
  components: { CreateEditProject, CreateEditRisk },
  data: () => ({
    Project: {},
    Risks: [],
    search: null,
    searchPermissions: null,
    searchExternalPermissions: null,
    AdminOrIsInSameCompany: false,
    footerProps: { 'items-per-page-options': [15, 30, 50, 100] },
  }),
  computed: {
    ...mapGetters(['isManager', 'isAdmin', 'user']),
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('RiskListHeaders.Risk'), value: 'title' },
        { text: t('RiskListHeaders.Probability'), value: 'probability' },
        { text: t('RiskListHeaders.TimePlan'), value: 'timeLine' },
        { text: t('RiskListHeaders.Expenses'), value: 'expenses' },
        { text: t('RiskListHeaders.Scope'), value: 'scope' },
        { text: t('RiskListHeaders.Resources'), value: 'resource' },
        { text: t('RiskListHeaders.Owner'), value: 'owner' },
      ];
    },
    getPermissionsHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('UserListHeaders.Name'), value: 'userName' },
        { text: t('UserListHeaders.Email'), value: 'userEmail' },
        { text: t('UserListHeaders.Permissions'), value: 'userPermissions' },
        { text: '', value: 'action' },
      ];
    },
    getCompanyUsers() {
      if (this.Project.permissions != undefined) {
        return this.Project.permissions.filter((x) => x.isExternal == false);
      } else {
        return [];
      }
    },
    getExternalUsers() {
      if (this.Project.permissions != undefined) {
        return this.Project.permissions.filter((x) => x.isExternal == true);
      } else {
        return [];
      }
    },
    HasFullAccessPermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasFullAccess(this.user, this.Project.permissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    async getProject() {
      this.Project = await apiService.getProjectById(this.$route.params.projectId);
      this.Risks = await apiService.getProjectRisks(this.Project.id);
      this.IsAdminOrIsInSameCompany();
    },
    EditProject() {
      this.$refs.CreateEditProjectRef.editProject(this.Project);
    },
    async IsAdminOrIsInSameCompany() {
      if (this.isAdmin) {
        this.AdminOrIsInSameCompany = true;
      }
      if (this.Project.companyId != null) {
        if (this.Project.companyId == this.user.companyId) {
          this.AdminOrIsInSameCompany = true;
        } else {
          this.AdminOrIsInSameCompany = false;
        }
      } else {
        this.AdminOrIsInSameCompany = false;
      }
    },
    async addRisk() {
      this.$refs.CreateEditRiskRef.createRisk();
    },
    bubbleEmit() {
      this.getProject();
    },
  },
  created() {
    this.getProject();
  },
};
</script>
