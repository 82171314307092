<template>
  <v-dialog v-model="show" max-width="900" persistent>
    <r-card v-if="currentProject">
      <template #header>
        <v-row no-gutters>
          <v-card-title>{{ isEditing ? $t('DialogsTitles.EditProject') : $t('DialogsTitles.AddNewProject') }} {{ ProjectName }}</v-card-title>
          <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
            <v-icon dark>mdi-close-thick</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card-text>
        <v-form v-model="isValid" ref="CreateEditProjectRef">
          <v-row>
            <v-col cols="12" v-if="isAdmin">
              <v-select dense :items="Companies" item-text="name" item-value="id" v-model="currentProject.companyId"> </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="currentProject.name" :rules="[rules.required]" dense :label="$t('ProjectListHeaders.Name')"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="currentProject.expenses"
                suffix="Kr"
                :rules="[rules.required]"
                dense
                :label="$t('ProjectListHeaders.Expenses')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" v-if="Users">
              <v-select
                dense
                :items="Users"
                item-text="name"
                :rules="[rules.required]"
                return-object
                v-model="selectedLeader"
                :label="$t('ProjectListHeaders.Leader')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="currentProject.start" :rules="[rules.required]" clearable :label="$t('ProjectListHeaders.StartDate')" />
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="currentProject.estimatedEnd" :rules="[rules.required]" clearable :label="$t('ProjectListHeaders.EstimatedEnd')" />
            </v-col>

            <v-col cols="12" v-if="selectedLeader" class="pt-4">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-row no-gutters>
                  <v-col class="my-4" cols="12" md="7">
                    <h2 class="my-auto">Permissions for project</h2>
                  </v-col>

                  <v-col class="my-4" cols="12" md="5" align="center">
                    <v-btn depressed @click="AddEntireCompany"> <v-icon left>mdi-domain</v-icon>{{ $t('ButtonsLabel.AddEntireCompany') }}</v-btn>
                  </v-col>
                </v-row>

                <v-row class="my-4" no-gutters>
                  <v-col cols="8">
                    <v-select
                      :items="usersForPermissions"
                      multiple
                      hide-details
                      v-model="usersChecked"
                      return-object
                      item-text="name"
                      label="Permissions"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" class="pl-2">
                    <v-btn depressed @click="AddSelected"> <v-icon left>mdi-account-plus-outline</v-icon> {{ $t('ButtonsLabel.AddSelected') }}</v-btn>
                  </v-col>
                  <v-col cols="8" class="mt-8">
                    <v-text-field v-model="userByEmail" hide-details dense :label="$t('UserListHeaders.Email')"></v-text-field>
                  </v-col>
                  <v-col cols="4" class="pl-2 mt-8">
                    <v-btn depressed @click="AddByEmail"> <v-icon left>mdi-account-plus-outline</v-icon> {{ $t('ButtonsLabel.AddByEmail') }}</v-btn>
                  </v-col>
                </v-row>

                <v-col cols="12" v-if="PermissionsUsers.length > 0">
                  <v-divider />
                </v-col>
                <v-col cols="6" class="mt-4">
                  <h2>{{ $t('UserListHeaders.InternalPermissionsUsers') }}</h2>
                </v-col>
                <v-col cols="6" v-if="PermissionsUsers.length > 0" class="mt-4">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="SearchPermissionsUsers"
                    :label="$t('message.Search')"
                    class="pa-4"
                    background-color="searchField"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="PermissionsUsers.length > 0">
                  <v-data-table :items="PermissionsUsers" :search="SearchPermissionsUsers" :headers="getHeaders" :items-per-page="5">
                    <template #[`item.permissionsId`]="{ item }">
                      <v-select
                        v-model="item.permissionsId"
                        class="pa-2"
                        :items="PermissionsList"
                        item-text="permissionsText"
                        item-value="id"
                        hide-details
                      ></v-select>
                    </template>
                    <template #[`item.action`]="{ item }">
                      <div class="text-right">
                        <v-btn icon @click="removeSelected(item)" :title="$t('ButtonsLabel.RemoveFromPermissions')">
                          <v-icon>mdi-account-remove-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="6" class="mt-4">
                  <h2>{{ $t('UserListHeaders.ExternalPermissionsUsers') }}</h2>
                </v-col>
                <v-col cols="6" v-if="ExternalPermissionsUsers.length > 0" class="mt-4">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="SearchExternalPermissionsUsers"
                    :label="$t('message.Search')"
                    class="pa-4"
                    background-color="searchField"
                  />
                </v-col>
                <v-col cols="12" v-if="ExternalPermissionsUsers.length > 0">
                  <v-data-table
                    :items="ExternalPermissionsUsers"
                    :search="SearchExternalPermissionsUsers"
                    :headers="getExternalUsersHeaders"
                    :items-per-page="5"
                  >
                    <template #[`item.permissionsId`]="{ item }">
                      <v-select
                        v-model="item.permissionsId"
                        class="pa-2"
                        :items="PermissionsList"
                        item-text="permissionsText"
                        item-value="id"
                        hide-details
                      ></v-select>
                    </template>
                    <template #[`item.action`]="{ item }">
                      <div class="text-right">
                        <v-btn icon @click="removeExternalSelected(item)" :title="$t('ButtonsLabel.RemoveFromPermissions')">
                          <v-icon>mdi-account-remove-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">{{ $t('ButtonsLabel.Cancel') }}</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="saveCurrentProject"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? $t('DialogsTitles.EditProject') : $t('DialogsTitles.AddNewProject') }}
        </v-btn>
      </v-card-actions>
    </r-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService';
import DatePicker from '@/components/DatePicker.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'CreateEditProject',
  components: { DatePicker },
  data: () => ({
    Companies: [],
    selectedLeader: null,
    show: false,
    users: [],
    currentProject: null,
    ProjectName: null,
    isValid: false,
    isSaving: false,
    picker: null,
    userByEmail: null,
    usersChecked: [],
    PermissionsUsers: [],
    ExternalPermissionsUsers: [],
    SearchPermissionsUsers: null,
    SearchExternalPermissionsUsers: null,
    StartDate: null,

    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),
  computed: {
    ...mapGetters(['isAdmin', 'user', 'isManager']),
    isEditing() {
      return this.currentProject?.id ? true : false;
    },
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('UserListHeaders.Name'), value: 'name' },
        { text: t('UserListHeaders.Email'), value: 'email' },
        { text: t('UserListHeaders.Permissions'), value: 'permissionsId' },
        { text: '', value: 'action' },
      ];
    },
    getExternalUsersHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('UserListHeaders.Email'), value: 'email' },
        { text: t('UserListHeaders.Permissions'), value: 'permissionsId' },
        { text: '', value: 'action' },
      ];
    },
    Users() {
      const users = this.users;
      if (this.isEditing) {
        if (!users.some((x) => x?.id == this.selectedLeader?.id)) {
          if (this.selectedLeader != null) users.push(this.selectedLeader);
        }
      }
      return users;
    },
    PermissionsList() {
      const t = this.$t.bind(this);
      return [
        {
          id: 0,
          permissionsText: t('PermissionsText.Read'),
        },
        {
          id: 1,
          permissionsText: t('PermissionsText.ReadAndWrite'),
        },
        {
          id: 2,
          permissionsText: t('PermissionsText.FullAccess'),
        },
      ];
    },
    usersForPermissions() {
      let usersMissing = this.users;
      usersMissing = usersMissing.filter((x) => x?.id != this.selectedLeader.id);
      usersMissing = usersMissing.filter((x) => !this.PermissionsUsers.some((y) => y?.id == x?.id));
      return usersMissing;
    },
  },
  watch: {
    selectedLeader() {
      if (this.PermissionsUsers.some((x) => x.id == this.selectedLeader.id)) {
        this.PermissionsUsers = this.PermissionsUsers.filter((x) => x?.id != this.selectedLeader.id);
      }
    },
  },
  methods: {
    async saveCurrentProject() {
      this.isSaving = true;

      if (!this.isAdmin) {
        this.currentProject.companyId = this.user.companyId;
      }
      this.currentProject.leader = this.selectedLeader;

      this.currentProject.permissions = [];
      this.currentProject.permissions.push({
        userId: this.currentProject.leader?.id,
        userPermissions: 2,
      });
      if (this.PermissionsUsers.length > 0) {
        this.PermissionsUsers.forEach((user) => {
          this.currentProject.permissions.push({
            userId: user.id,
            userPermissions: user.permissionsId,
            isExternal: false,
          });
        });
      }
      if (this.ExternalPermissionsUsers.length > 0) {
        this.ExternalPermissionsUsers.forEach((user) => {
          this.currentProject.permissions.push({
            email: user.email,
            userPermissions: user.permissionsId,
            isExternal: true,
          });
        });
      }

      await apiService
        .updateProject(this.currentProject)
        .then(() => {
          this.isSaving = false;
          this.PermissionsUsers = [];
          this.ExternalPermissionsUsers = [];
          this.show = false;
          this.$emit('project-updated');
        })
        .catch(() => {
          this.currentProject.permissions = [];
        });
    },
    async GetCompanies() {
      if (this.isAdmin) {
        this.Companies = await apiService.getCompanies();
      }
    },
    editProject(Project) {
      this.GetCompanies();
      this.GetUsers();
      this.currentProject = _.cloneDeep(Project);
      this.ProjectName = this.currentProject.name;
      this.selectedLeader = this.currentProject.leader;
      this.currentProject.permissions.forEach((user) => {
        if (user.isExternal == false) {
          this.PermissionsUsers.push({
            id: user.userId,
            name: user.userName,
            email: user.userEmail,
            permissionsId: user.userPermissions,
            isExternal: false,
          });
        } else {
          this.ExternalPermissionsUsers.push({
            id: user.userId,
            name: user.userName,
            email: user.userEmail,
            permissionsId: user.userPermissions,
            isExternal: true,
          });
        }
      });
      this.show = true;
    },
    async GetUsers() {
      if (this.isManager) {
        const data = await apiService.getUserCompany();
        this.users = data.users;
      }
    },
    createProject() {
      this.GetCompanies();
      this.currentProject = {
        companyId: null,
        name: null,
        leader: null,
        expenses: null,
        address: null,
        estimatedEnd: null,
        start: null,
        permissions: [],
        AmountOfRisks: 0,
      };
      this.GetUsers();
      this.usersChecked = [];
      (this.PermissionsUsers = []), (this.ExternalPermissionsUsers = []), (this.show = true), (this.selectedLeader = null);
    },
    AddEntireCompany() {
      if (this.usersForPermissions.length > 0) {
        this.usersForPermissions.forEach((user) => {
          this.PermissionsUsers.push({
            id: user.id,
            name: user.name,
            email: user.email,
            permissionsId: 1,
          });
        });
      }
    },
    AddSelected() {
      if (this.usersChecked.length > 0) {
        this.usersChecked.forEach((user) => {
          this.PermissionsUsers.push({
            id: user.id,
            name: user.name,
            email: user.email,
            permissionsId: 1,
          });
        });
        this.usersChecked = [];
      }
    },
    async AddByEmail() {
      if (!this.ExternalPermissionsUsers.some((x) => x.email == this.userByEmail)) {
        this.ExternalPermissionsUsers.push({
          email: this.userByEmail,
          permissionsId: 1,
        });
        this.userByEmail = null;
      }
    },
    removeSelected(val) {
      this.PermissionsUsers = this.PermissionsUsers.filter((x) => x.id != val.id);
    },
    removeExternalSelected(val) {
      this.ExternalPermissionsUsers = this.ExternalPermissionsUsers.filter((x) => x.id != val.id);
    },
  },
};
</script>

<style></style>
